import axios from "axios";
import { useQuery } from "react-query";

const jsonConfigAPI = axios.create({
  baseURL: "https://cliqueiachei.com.br/json-config",
  headers: {
    "Cache-Control": "no-cache, no-store, must-revalidate",
  },
});

const fetchConfig = () => jsonConfigAPI.get("/configuracoes-cadastro.json");

export function useJSONConfig() {
  const result = useQuery("configuracoes-cadastro", () => fetchConfig());
  return { ...result, data: result.data?.data };
}
