import React from "react";
import { mask, unMask } from "remask";

export default function OwnerInformationStep({
  sponserFirstName,
  setSponserFirstName,
  sponserLastName,
  setSponserLastName,
  emailpersonal,
  setEmailPersonal,
  whatsappPersonal,
  setWhatsAppPersonal,
  verifyPrimaryBox,
  internalbox,
}) {
  return (
    <div className="fadeIn">
      <div className="formbox">
        <div>
          <div className="titleInformation">
            <h5 className="itemtitle">Informações internas do Empresário</h5>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6   mb-3">
              <label>Primeiro nome</label>
              <div className="input-group mb-1">
                <input
                  value={sponserFirstName.value}
                  onChange={(e) => {
                    setSponserFirstName({
                      ...sponserFirstName,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                  placeholder="Ex.: João"
                  type="text"
                  className={`form-control-style-custom ${
                    sponserFirstName.error ? "error" : ""
                  }`}
                />
                {sponserFirstName.error && (
                  <small className="form-text text-danger">
                    Insira o seu primeiro nome
                  </small>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-6  mb-3">
              <label>Sobrenome</label>
              <div className="input-group mb-1">
                <input
                  value={sponserLastName.value}
                  onChange={(e) => {
                    setSponserLastName({
                      ...sponserLastName,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                  placeholder="Ex.: Silva"
                  type="text"
                  className={`form-control-style-custom ${
                    sponserLastName.error ? "error" : ""
                  }`}
                />
                {sponserLastName.error && (
                  <small className="form-text text-danger">
                    Insira seu sobrenome
                  </small>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-6  mb-3">
              <label>E-mail pessoal</label>
              <div className="input-group mb-1">
                <input
                  value={emailpersonal.value}
                  onChange={(e) => {
                    setEmailPersonal({
                      ...emailpersonal,
                      value: e.target.value.toLowerCase().trim(),
                      error: false,
                    });
                  }}
                  placeholder="Ex.: joao@gmail.com"
                  type="email"
                  className={`form-control-style-custom ${
                    emailpersonal.error ? "error" : ""
                  }`}
                />
              </div>
              {emailpersonal.error && (
                <small className="form-text text-danger">
                  Insira o seu e-mail pessoal
                </small>
              )}
            </div>
            <div className="col-sm-12 col-md-6 ">
              <label>WhatsApp Pessoal</label>
              <div className="input-group">
                <input
                  maxLength={15}
                  value={whatsappPersonal.masked}
                  onChange={(e) => {
                    setWhatsAppPersonal({
                      ...whatsappPersonal,
                      value: unMask(e.target.value, ["(99) 99999-9999"]),
                      error: false,
                      masked: mask(e.target.value, ["(99) 99999-9999"]),
                    });
                  }}
                  placeholder="(XX) XXXXX-XXXX"
                  type="tel"
                  className={`form-control-style-custom ${
                    whatsappPersonal.error ? "error" : ""
                  }`}
                />
              </div>
              {whatsappPersonal.error && (
                <small className="form-text text-danger">
                  Insira o seu WhatsApp
                </small>
              )}
              <small className="form-text text-muted">
                Caso precise recuperar sua senha do Cliquei Achei ID você deverá
                realizar o procedimento através do celular no qual este número
                de WhatsApp está configurado.
              </small>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-sm-6 col-md-6  col-lg-3"></div>
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="continueBox">
                <div
                  className={
                    internalbox.checked ? "btn-continue" : "btn-disable"
                  }
                  onClick={() => verifyPrimaryBox()}
                >
                  Próximo
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
