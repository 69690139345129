export class Validators {
  static cellphone(strNumber = "") {
    if (strNumber.length < 11) return false;
    return true;
  }

  static landline(strNumber = "") {
    if (strNumber.length < 10) return false;
    return true;
  }
}
