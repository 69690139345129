import { useMemo } from "react";

export function useProgressValue({
  internalBox,
  publicBusiness,
  registerScreen,
  thanksPage,
}) {
  return useMemo(() => {
    let result = 5;
    if (internalBox.checked) {
      return 33.333;
    }

    if (publicBusiness.checked && internalBox.checked) {
      result = 66.666;
    }

    if (
      publicBusiness.checked &&
      internalBox.checked &&
      registerScreen.checked
    ) {
      result = 95;
    }

    if (
      publicBusiness.checked &&
      internalBox.checked &&
      registerScreen.checked &&
      thanksPage
    ) {
      result = 100;
    }

    return result;
  }, [
    internalBox.checked,
    publicBusiness.checked,
    registerScreen.checked,
    thanksPage,
  ]);
}

// useEffect(() => {
//   if (internalbox.checked) {
//     setValueProgress(33.333);
//   }

//   if (publicBusiness.checked && internalbox.checked) {
//     setValueProgress(66.666);
//   }

//   if (
//     publicBusiness.checked &&
//     internalbox.checked &&
//     registerScreen.checked
//   ) {
//     setValueProgress(95);
//   }

//   if (
//     publicBusiness.checked &&
//     internalbox.checked &&
//     registerScreen.checked &&
//     thankspage
//   ) {
//     setValueProgress(100);
//   }

//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [
//   internalbox.checked,
//   publicBusiness.checked,
//   registerScreen.checked,
//   thankspage,
// ]);
