import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import TextField from "@material-ui/core/TextField";
import { mask, unMask } from "remask";
import { Switch } from "@material-ui/core";

export default function BusinessInformationStep({
  tagSelect,
  handleCloseTag,
  scroll,
  franchisedRegistration,
  descriptionElementRef,
  open,
  handleClose,
  fantasyName,
  setFantasyName,
  mainActivity,
  setMainActivity,
  tagError,
  handleClickOpen,
  handleClickTag,
  tagSelectedOption,
  cityError,
  cityId,
  address,
  setAddress,
  addressNumber,
  setAddressNumber,
  neighborhood,
  setNeighborhood,
  cep,
  setCep,
  complement,
  setComplement,
  cellphones,
  setCellphones,
  phoneError,
  setPhoneError,
  setSelectProdutc,
  selectProdutc,
  selectProdutcError,
  setSelectProdutcError,
  alertError,
  setAlertError,
  landline,
  setLandline,
  operatorError,
  setOperatorError,
  mobileOperators,
  handleNewPhone,
  publicBusiness,
  verifyThirdBox,
  searchTerm,
  setSearchTerm,
  setCityId,
  setOpen,
  citiesFinded,
  searchTag,
  setSearchTag,
  tagResultListed,
  setTagSelectedOption,
  setTagSelect,
  cellphoneIsOptional,
  landlineIsOptional,
}) {
  return (
    <div>
      <div className="formbox fadeIn">
        <Dialog
          open={tagSelect}
          onClose={handleCloseTag}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          style={{ outline: "none" }}
        >
          <DialogTitle id="scroll-dialog-title">
            Selecione a categoria
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
              style={{ outline: "none", maxHeight: 435 }}
            >
              <TextField
                autoFocus
                fullWidth
                id="outlined-basic"
                label="Digite a categoria"
                variant="outlined"
                value={searchTag}
                onChange={(e) => {
                  setSearchTag(e.target.value);
                }}
              />
              <div className="listCities">
                <div>
                  {tagResultListed.slice(0, 10).map((itens, index) => {
                    return (
                      <div
                        className="cityItem"
                        onClick={() => {
                          setTagSelectedOption(itens);
                          setTagSelect(false);
                          setSearchTag("");
                        }}
                      >
                        <div className="squareBox"></div>
                        <span>{itens.Nome}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseTag} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          style={{ outline: "none" }}
        >
          <DialogTitle id="scroll-dialog-title">Selecione a cidade</DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
              style={{ outline: "none", maxHeight: 435 }}
            >
              <TextField
                autoFocus
                fullWidth
                id="outlined-basic"
                label="Digite o nome da cidade"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />

              <div className="listCities">
                {citiesFinded.slice(0, 20).map((itens) => {
                  return (
                    <div
                      className="cityItem"
                      onClick={() => {
                        setCityId(itens);
                        setOpen(false);
                        setSearchTerm("");
                      }}
                    >
                      <div className="squareBox"></div>
                      <span>
                        {itens.Nom_Cidade}, {itens.Sigla_UF}
                      </span>
                    </div>
                  );
                })}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
        <div>
          <div className="titleInformation">
            <h5 className="itemtitle">Informações Públicas do Negócio</h5>
            <p>
              As informações abaixo ficarão disponíveis em seu cadastro do
              Cliquei Achei.
            </p>
          </div>

          {franchisedRegistration && (<div className="row">
            <div className="col-sm-6  mb-3">
              <label>Produto</label>
              <div className="input-group">
                <select
                  className={`custom-select-item ${selectProdutcError ? "error" : ""
                    }`}
                  id="selectProdutc"
                  placeholder="Escolha..."
                  defaultChecked={0}
                  onChange={ e => setSelectProdutc(e.target.value) }
                >
                  <option
                    value={0}
                  >
                    Escolha o produto...
                  </option>

                  <option
                    value={1}
                  >
                    ListaZap
                  </option>

                  <option
                    value={3}
                  >
                    Pedidos WhatsApp
                  </option>

                  <option
                    value={6}
                  >
                    Agenda
                  </option>
                </select>
              </div>
            </div>
          </div>)}

          <div className="row">
            <div className="col-sm-12 col-md-6  mb-3">
              <label>Nome Fantasia</label>
              <div className="input-group mb-1">
                <input
                  placeholder="Ex.: Padaria São Josiano"
                  type="text"
                  className={`form-control-style-custom ${fantasyName.error ? "error" : ""
                    }`}
                  value={fantasyName.value}
                  onChange={(e) => {
                    setFantasyName({
                      ...fantasyName,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                />
              </div>
              {fantasyName.error && (
                <small className="form-text text-danger">
                  Insira o nome fantasia
                </small>
              )}
            </div>

            <div className="col-sm-12 col-md-6  mb-3">
              <label>Qual é a Atividade Principal do seu negócio?</label>
              <div className="input-group mb-1">
                <input
                  placeholder="Ex.: Panificadora"
                  type="text"
                  className={`form-control-style-custom ${mainActivity.error ? "error" : ""
                    }`}
                  value={mainActivity.value}
                  onChange={(e) => {
                    setMainActivity({
                      ...mainActivity,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                />
              </div>
              {mainActivity.error && (
                <small className="form-text text-danger">
                  Insira a atividade principal do seu negócio
                </small>
              )}
            </div>
            <div className="col-sm-6 mb-3">
              <label>Categoria</label>
              <div className="input-group mb-1">
                <Button
                  variant="outlined"
                  color={tagError ? "secondary" : "primary"}
                  onClick={handleClickTag("paper")}
                  style={{
                    width: "100%",
                    backgroundColor: "#fff",
                    padding: 9,
                    textTransform: "unset",
                  }}
                >
                  {tagSelectedOption.CategoriaId
                    ? tagSelectedOption.Nome
                    : "Clique para escolher a categoria"}
                </Button>
              </div>
              {tagError && (
                <small className="form-text text-danger">
                  Escolha a categoria do seu negócio
                </small>
              )}
            </div>
            <div className="col-sm-6 mb-3">
              <label>Cidade</label>
              <div className="input-group mb-1">
                <Button
                  variant="outlined"
                  color={cityError ? "secondary" : "primary"}
                  onClick={handleClickOpen("paper")}
                  style={{
                    width: "100%",
                    backgroundColor: "#fff",
                    padding: 9,
                    textTransform: "unset",
                  }}
                >
                  {cityId.Nom_Cidade !== ""
                    ? cityId.Nom_Cidade + ", " + cityId.Sigla_UF
                    : "Clique para escolher a cidade"}
                </Button>
              </div>
              {cityError && (
                <small className="form-text text-danger">
                  Escolha a cidade
                </small>
              )}
            </div>
            <div className="col-sm-9 col-md-9  mb-3">
              <label>Endereço</label>
              <div className="input-group mb-1">
                <input
                  placeholder="Ex.: Rua Horizonte Alto"
                  type="text"
                  className={`form-control-style-custom ${address.error ? "error" : ""
                    }`}
                  value={address.value}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                />
              </div>
              {address.error && (
                <small className="form-text text-danger">
                  Digite o seu endereço
                </small>
              )}
            </div>
            <div className="col-sm-3 col-md-3  mb-3">
              <label>Número</label>
              <div className="input-group mb-1">
                <input
                  placeholder="Ex.: 100"
                  type="number"
                  className={`form-control-style-custom ${addressNumber.error ? "error" : ""
                    }`}
                  value={addressNumber.value}
                  onChange={(e) => {
                    setAddressNumber({
                      ...addressNumber,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                />
              </div>
              {addressNumber.error && (
                <small className="form-text text-danger">
                  Digite número do endereço
                </small>
              )}
            </div>

            <div className="col-sm-12 col-md-4  mb-3">
              <label>Bairro</label>
              <div className="input-group mb-1">
                <input
                  placeholder="Ex.: Centro"
                  type="text"
                  className={`form-control-style-custom ${neighborhood.error ? "error" : ""
                    }`}
                  value={neighborhood.value}
                  onChange={(e) => {
                    setNeighborhood({
                      ...neighborhood,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                />
              </div>
              {neighborhood.error && (
                <small className="form-text text-danger">
                  Digite seu bairro
                </small>
              )}
            </div>

            <div className="col-sm-6 col-md-4 mb-3 ">
              <label>CEP</label>
              <div className="input-group">
                <input
                  maxLength={10}
                  className={`form-control-style-custom ${cep.error ? "error" : ""
                    }`}
                  value={cep.masked}
                  onChange={(e) => {
                    setCep({
                      ...cep,
                      value: unMask(e.target.value, ["99.999-999"]),
                      error: false,
                      masked: mask(e.target.value, ["99.999-999"]),
                    });
                  }}
                  placeholder="00.000-000"
                  type="tel"
                />
              </div>
              {cep.error && (
                <small className="form-text text-danger">Insira seu CEP</small>
              )}
            </div>

            <div className="col-sm-6  col-md-4 mb-3 ">
              <label>
                Complemento <div className="gray">(opcional)</div>
              </label>
              <div className="input-group">
                <input
                  maxLength={10}
                  className="form-control-style-custom"
                  value={complement.value}
                  onChange={(e) => {
                    setComplement({
                      ...complement,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                  placeholder="Ex.: Sala 2"
                  type="text"
                />
              </div>
            </div>

            {cellphones.map((items, index) => {
              return (
                <div className="fadeIn">
                  <div className="row col-sm-12">
                    <div className="col-sm-5 mb-3 ">
                      <label>
                        Celular{" "}
                        {cellphoneIsOptional && (
                          <div className="gray">(opcional)</div>
                        )}
                      </label>
                      <div className="input-group">
                        <input
                          maxLength={15}
                          value={cellphones[index].masked}
                          onChange={(e) => {
                            cellphones[index] = {
                              ...cellphones[index],
                              value: unMask(e.target.value, [
                                "(99) 99999-9999",
                              ]),
                              error: false,
                              masked: mask(e.target.value, ["(99) 99999-9999"]),
                            };
                            setCellphones([...cellphones]);
                            setPhoneError(false);
                          }}
                          placeholder="(XX) XXXXX-XXXX"
                          type="tel"
                          className={`form-control-style-custom ${phoneError ? "error" : ""
                            }`}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3  mb-3 ">
                      <label>Operadora</label>
                      <div className="input-group">
                        <select
                          className={`custom-select-item ${phoneError || operatorError ? "error" : ""
                            }`}
                          id="inputGroupSelect02"
                          onChange={(e) => {
                            cellphones[index] = {
                              ...cellphones[index],

                              mobile: e.target.value,
                            };
                            setCellphones([...cellphones]);
                            setOperatorError(false);
                          }}
                        >
                          <option selected>Escolha...</option>
                          {mobileOperators.map((itens, index) => {
                            return (
                              <option
                                key={String(index)}
                                value={itens.OperadoraId}
                              >
                                {itens.Nome}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-2  mb-3 ">
                      <label>WhatsApp</label>

                      <Switch
                        checked={cellphones[index].haveWhatsApp}
                        onChange={(e) => {
                          cellphones[index] = {
                            ...cellphones[index],

                            haveWhatsApp: cellphones[index].haveWhatsApp
                              ? false
                              : true,
                          };
                          setCellphones([...cellphones]);
                        }}
                        color="primary"
                        name="checkedA"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>

                    {cellphones.length > 1 ? (
                      <div className="col-sm-2  mb-3 ">
                        <div className="delete-button">
                          <div
                            onClick={(e) => {
                              delete cellphones[index];
                              setCellphones(
                                [...cellphones].filter(
                                  (phone) => phone !== undefined
                                )
                              );
                            }}
                          >
                            <Tooltip title="Apagar número">
                              <IconButton
                                aria-label="delete"
                                style={{ outline: "none" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
            {phoneError && (
              <div className="col-sm-12 mb-3">
                <small className="form-text text-danger">
                  Insira ao menos um telefone celular
                </small>
              </div>
            )}
            <div className={alertError ? "col-sm-12 mb-3" : "col-sm-12"}>
              <Collapse in={alertError}>
                <Alert
                  severity="warning"
                  variant="filled"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Você poderá adicionar novos telefones ou editar
                  posteriormente.
                </Alert>
              </Collapse>
            </div>

            {cellphones[0].value >= 11 ? (
              alertError ? null : (
                <div className="col-sm-12 mb-3">
                  <div>
                    <Button
                      onClick={() => {
                        handleNewPhone();
                      }}
                      color="primary"
                    >
                      Adicionar outro telefone
                    </Button>
                  </div>
                </div>
              )
            ) : null}

            <div className="col-sm-3">
              <label>
                Telefone Fixo{" "}
                {landlineIsOptional && <div className="gray">(opcional)</div>}
              </label>

              <div className="input-group mb-3">
                <input
                  placeholder="(XX) XXXX-XXXX"
                  type="tel"
                  className={`form-control-style-custom ${landline.error ? "error" : ""
                    }`}
                  value={landline.value}
                  onChange={(e) => {
                    setLandline({
                      ...landline,
                      value: e.target.value,
                      error: false,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-sm-6 col-md-6  col-lg-3"></div>
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="continueBox">
                <div
                  className={
                    publicBusiness.checked ? "btn-continue" : "btn-disable"
                  }
                  onClick={() => verifyThirdBox()}
                >
                  Próximo
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
