import { useMemo } from "react";

export function useVideoConfig({
  isPedidos,
  isDivulgador,
  isListaZap,
  jsonConfig,
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const customVideoParam = urlParams.get("video");

  const videoConfig = useMemo(() => {
    if (jsonConfig && jsonConfig?.videos?.[customVideoParam]) {
      return {
        title: "Vídeo Customizado",
        src: jsonConfig.videos[customVideoParam].src,
      };
    }

    if (isPedidos) {
      return {
        title: "Vídeo Pedidos",
        src: "//embed.vidello.com/4125/fd1nykw2pdqs17c6/player.html",
        className: "mobiledeskspacing",
      };
    }

    if (isListaZap) {
      return {
        title: "Vídeo Lista Zap",
        src: "//embed.vidello.com/4125/qgbbvaffkryfpj6b/player.html",
      };
    }

    if (isDivulgador) {
      return {
        title: "Vídeo Divulgador",
        src: "//embed.vidello.com/4125/k0c952mrqpaxmxg2/player.html",
      };
    }

    return {
      title: "Vídeo Padrão",
      src: "//embed.vidello.com/4125/qgbbvaffkryfpj6b/player.html",
    };
  }, [jsonConfig, customVideoParam, isDivulgador, isListaZap, isPedidos]);

  return videoConfig;
}

// {isPedidos && (
//   <iframe
//     title="Vídeo Pedidos"
//     className="mobiledeskspacing"
//     src="//embed.vidello.com/4125/fd1nykw2pdqs17c6/player.html"
//     autoplay
//     frameborder="0"
//     allow="autoplay; fullscreen"
//     allowfullscreen
//   />
// )}

// {isListaZap && (
//   <iframe
//     title="Vídeo Lista Zap"
//     src="//embed.vidello.com/4125/qgbbvaffkryfpj6b/player.html"
//     autoplay
//     frameborder="0"
//     allow="autoplay; fullscreen"
//     allowfullscreen
//   />
// )}

// {isDivulgador && (
//   <iframe
//     title="Vídeo Divulgador"
//     src="//embed.vidello.com/4125/k0c952mrqpaxmxg2/player.html"
//     autoplay
//     frameborder="0"
//     allow="autoplay; fullscreen"
//     allowfullscreen
//   />
// )}

// {!isDivulgador && !isListaZap && !isPedidos && (
//   <iframe
//     title="Vídeo Padrão"
//     src="//embed.vidello.com/4125/qgbbvaffkryfpj6b/player.html"
//     autoplay
//     frameborder="0"
//     allow="autoplay; fullscreen"
//     allowfullscreen
//   />
// )}
