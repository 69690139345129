import { useMemo } from "react";
import divulgadorLogo from "../../assets/divulgador_logo.svg";
import barrigaLogo from "../../assets/logo-cardapio-do-barriga.svg";
import pedidosLogo from "../../assets/pedidos_logo.svg";
import listaZapLogo from "../../assets/listazap.svg";
import logo from "../../assets/logo_cliquei.svg";

export function useLogoConfig({
  isPedidos,
  isDivulgador,
  isListaZap,
  isBarriga,
  isAgenda,
  jsonConfig,
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const customLogoParam = urlParams.get("logo");

  const logoConfig = useMemo(() => {
    if (jsonConfig && jsonConfig?.logos?.[customLogoParam]) {
      return {
        src: jsonConfig.logos[customLogoParam].src,
      };
    }

    if (isPedidos) {
      return { src: pedidosLogo };
    }

    if (isListaZap) {
      return { src: listaZapLogo };
    }
  
    if (isAgenda) {
      return { src: listaZapLogo };
    }

    if (isDivulgador) {
      return { src: divulgadorLogo };
    }

    if (isBarriga) {
      return { src: barrigaLogo };
    }

    return { src: logo };
  }, [
    jsonConfig,
    customLogoParam,
    isPedidos,
    isListaZap,
    isDivulgador,
    isBarriga,
  ]);

  return logoConfig;
}
