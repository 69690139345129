import { useMemo } from "react";

export function usePageModel() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const productQuery = urlParams.get("produto");

  const isPedidos = productQuery === "1834759"; //    Pedidos WhatsApp = 1834759
  const isListaZap = productQuery === "653792"; //    ListaZap = 653792
  const isDivulgador = productQuery === "1676624"; // Divulgador = 1676624
  const isBarriga = productQuery === "1812015"; //    Cardápio do Barriga = 1812015
  const isAgenda = productQuery === "1932779"; //     Agenda = 1932779

  const productName = useMemo(() => {
    if (isPedidos) return "Pedidos WhatsApp";
    if (isListaZap) return "ListaZap";
    if (isDivulgador) return "Divulgador";
    if (isBarriga) return "Cardápio do Barriga";
    if (isAgenda) return "Agenda";
    return "";
  }, [isBarriga, isDivulgador, isListaZap, isPedidos, isAgenda]);

  const pageModelId = useMemo(() => {
    if (isBarriga) return "5";
    if (isAgenda) return "6";
    return isPedidos ? "3" : "1";
  }, [isBarriga, isPedidos]);

  return {
    isPedidos,
    isListaZap,
    isDivulgador,
    isBarriga,
    isAgenda,
    productName,
    pageModelId,
  };

}
