import { useMemo } from "react";

export function usePaymentStatus() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const statusPayment = urlParams.get("status");

  const paymentStatusLabel = useMemo(() => {
    if (statusPayment === "paid") return "Pagamento confirmado";
    if (statusPayment === "awaitingpayment")
      return "Aguardando confirmação do pagamento";
    return "";
  }, [statusPayment]);

  const messageHead = useMemo(() => {
    if (statusPayment === "paid")
      return "O seu pagamento foi aprovado! Agora cadastre seu estabelecimento para começar o usufruir de todos os benefícios do Cliquei Achei.";
    if (statusPayment === "awaitingpayment")
      return "O seu pagamento ainda não foi aprovado, mesmo assim nós liberamos seu acesso para que possa começar a usufruir de todos os benefícios do Cliquei Achei. Então agora cadastre seu estabelecimento para começar e não esqueça de realizar o pagamento, pois sem a confirmação todos os benefícios são bloqueados automaticamente pelo nosso sistema.";
    return "";
  }, [statusPayment]);

  return { messageHead, paymentStatusLabel };

  // switch (statusPayment) {
  //   case "paid":
  //     setPaymentStatusLabel("Pagamento confirmado");
  //     setMessageHead(
  //       "O seu pagamento foi aprovado! Agora cadastre seu estabelecimento para começar o usufruir de todos os benefícios do Cliquei Achei."
  //     );
  //     break;

  //   case "awaitingpayment":
  //     setPaymentStatusLabel("Aguardando confirmação do pagamento");
  //     setMessageHead(
  //       "O seu pagamento ainda não foi aprovado, mesmo assim nós liberamos seu acesso para que possa começar a usufruir de todos os benefícios do Cliquei Achei. Então agora cadastre seu estabelecimento para começar e não esqueça de realizar o pagamento, pois sem a confirmação todos os benefícios são bloqueados automaticamente pelo nosso sistema."
  //     );
  //     break;

  //   default:
  //     console.log(`Sorry, we are out of ${statusPayment}.`);
  // }
}
