import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import whatsappIcon from "../../assets/whatsappIcon.svg";
import divulgadorSmall from "../../assets/divulgadorSmall.svg";
import appleIcon from "../../assets/appleIcon.svg";
import androidIcon from "../../assets/androidIcon.svg";
import printerSmall from "../../assets/icone/printerSmall.svg";
import cliqueiacheiSmall from "../../assets/cliqueiacheiSmall.svg";
import LinearProgress from "@material-ui/core/LinearProgress";
import Switch from "@material-ui/core/Switch";
import cities from "../../data/cities.json";

import axios from "axios";
import ReactGA from "react-ga";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";
import OwnerInformationStep from "./OwnerInformationStep";
import BusinessInformationStep from "./BusinessInformationStep";

import { Validators } from "../../utils/validators";
import { useProgressValue } from "./use-progress-value";
import { usePageModel } from "./use-page-model";
import { usePaymentStatus } from "./use-payment-status";
import Loading from "./Loading";
import { useJSONConfig } from "./use-json-config";
import { useVideoConfig } from "./use-video-config";
import { useLogoConfig } from "./use-logo-config";

const isProduction = process.env.NODE_ENV === "production";

const DEFAULT_INPUT_VALUES = {
  personalEmail: isProduction ? "" : "jefersoncliqueiachei@gmail.com",
  firstName: isProduction ? "" : "Jeferson",
  lastName: isProduction ? "" : "Santos",
  personalWhatsappMasked: isProduction ? "" : "(35) 99764-8670",
  personalWhatsapp: isProduction ? "" : "35997648670",
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#f03e3e",
  },
}));

export default function Main() {
  const [open, setOpen] = useState(false);
  const [tagSelect, setTagSelect] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [mobileOperators, setMobileOperators] = useState([]);
  const [pageView, setPageView] = useState("");
  const [loadPage, setLoadPage] = useState(true);
  const [tokenId, setTokenId] = useState("");
  const [invalidCode, setInvalidCode] = useState(false);
  const defaultTitle = "Cadastre-se no Cliquei Achei | Dados do Empresário";
  const titlepage = useRef(defaultTitle);
  const [franchisedRegistration, setFranchisedRegistration] = useState(false);
  const [hotmartClientName, setHotmartClientName] = useState("");

  const {
    isDivulgador,
    isListaZap,
    isPedidos,
    isBarriga,
    isAgenda,
    productName,
    pageModelId,
  } = usePageModel();

  const { data: config } = useJSONConfig();

  const videoConfig = useVideoConfig({
    isDivulgador,
    isPedidos,
    isListaZap,
    jsonConfig: config,
  });

  const logoConfig = useLogoConfig({
    isDivulgador,
    isPedidos,
    isListaZap,
    isBarriga,
    isAgenda,
    jsonConfig: config,
  });

  const { paymentStatusLabel, messageHead } = usePaymentStatus();

  useEffect(() => {
    function initializeReactGA() {
      var utm = window.location.search;
      const veryfied = "?hotmart=836bb4c7c6f0edd302b017dbf194be14&verify=true";

      if (utm === veryfied) {
        console.log("ReactGA.initialize");
        ReactGA.initialize("UA-32686641-1");
      }
    }

    initializeReactGA();
  }, []);

  useEffect(() => {
    window.document.title = titlepage.current;
    ReactGA.pageview(`/cadastro/${pageView}`);
  }, [pageView]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const transaction = urlParams.get("transaction");
    const modelPage = urlParams.get("produto");
    setHotmartClientName(urlParams.get("c_name"));
    const franchise = "?register=015dd5d9b9d20ecf7c08e3e69cc5e9fe&verify=true&isApp=true";

    async function checkTransaction() {
      try {
        const res = await axios.get(
          `https://api.cliqueiachei.com.br/v1/clientes/check-transaction?transactionid=${transaction}`
        );

        if (res.data.valid) {
          setLoadPage(false);
          setTokenId(transaction);
        }

      } catch (error) {
        setLoadPage(false);
        setThankspage(true);
        setInternalBox(false);
        setInvalidCode(true);
      }
    }

    if (transaction) {
      setTimeout(() => {
        checkTransaction();
      }, 2000);
    } else {
      if (queryString === franchise) {
        setFranchisedRegistration(true);
        setLoadPage(false);
      } else {
        switch (modelPage) {
          case "divulgador":
            window.open("https://divulgador.cliqueiachei.app", "_self");
            break;

          case "listazap":
            window.open("https://listazap.cliqueiachei.app", "_self");
            break;

          case "pedidoswhatsapp":
            window.open("https://pedidos.cliqueiachei.app", "_self");
            break;

          default:
            window.open("https://cliqueiachei.app", "_self");
            break;
        }
      }
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://api.cliqueiachei.com.br/v1/categorias?page=1&pagesize=3000&search=`
      )
      .then((res) => {
        setTagResult(res.data.objects);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://api.cliqueiachei.com.br/v1/operadoras?page=1&pagesize=30&search=`
      )
      .then((res) => {
        setMobileOperators(res.data);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
    setCityError(false);
  };

  const handleClickTag = (scrollType) => () => {
    setTagSelect(true);
    setTagError(false);
  };

  const handleClose = () => {
    setOpen(false);

    if (cityId.Cod_Cidade === 0) {
      setCityError(true);
    }
  };

  const handleCloseTag = () => {
    setTagSelect(false);
    setSearchTag("");
    if (!tagSelectedOption.CategoriaId) {
      setTagError(true);
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [emailpersonal, setEmailPersonal] = useState({
    value: DEFAULT_INPUT_VALUES.personalEmail,
    verify: false,
    error: false,
  });

  const [whatsappPersonal, setWhatsAppPersonal] = useState({
    value: DEFAULT_INPUT_VALUES.personalWhatsapp,
    verify: false,
    error: false,
    masked: DEFAULT_INPUT_VALUES.personalWhatsappMasked,
  });

  const [sponserFirstName, setSponserFirstName] = useState({
    value: DEFAULT_INPUT_VALUES.firstName,
    verify: false,
    error: false,
  });

  const [sponserLastName, setSponserLastName] = useState({
    value: DEFAULT_INPUT_VALUES.lastName,
    verify: false,
    error: false,
  });

  const [cityId, setCityId] = useState({
    Cod_Cidade: 0,
    Nom_Cidade: "",
    Url_Cidade: "",
    Sigla_UF: "",
  });

  const [internalbox, setInternalBox] = useState({
    visible: true,
    checked: false,
  });

  const [registerScreen, setRegisterScreen] = useState({
    visible: false,
    checked: false,
  });

  const [publicBusiness, setPublicBusiness] = useState({
    visible: false,
    checked: false,
  });

  const [cellphones, setCellphones] = useState([
    {
      mobile: "",
      haveWhatsApp: false,
      value: "",
      masked: "",
      verify: false,
      error: false,
    },
  ]);

  function handleNewPhone() {
    if (cellphones.length < 3) {
      setCellphones([
        ...cellphones,
        {
          mobile: "",
          haveWhatsApp: false,
          value: "",
          masked: "",
          verify: false,
          error: false,
        },
      ]);
    } else {
      setAlertError(true);
    }
  }

  function verifyPrimaryBox() {
    if (sponserFirstName.value.length <= 0) {
      setSponserFirstName({ ...sponserFirstName, error: true });
    }

    if (sponserLastName.value.length <= 0) {
      setSponserLastName({ ...sponserLastName, error: true });
    }

    if (
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailpersonal.value)
    ) {
    } else {
      setEmailPersonal({ ...emailpersonal, error: true });
    }

    if (whatsappPersonal.value.length <= 10) {
      setWhatsAppPersonal({ ...whatsappPersonal, error: true });
      console.log("nao validado whatsapp!");
    }

    if (internalbox.checked && !emailpersonal.error) {
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailpersonal.value)
      ) {
        setPageView("dados-do-empresario");
        titlepage.current = "Cadastre-se no Cliquei Achei | Dados da Empresa";
        setInternalBox({ ...internalbox, visible: false });
        setPublicBusiness({ ...publicBusiness, visible: true });
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setEmailPersonal({ ...emailpersonal, error: true });
      }
    }
  }

  useEffect(() => {
    if (sponserFirstName.value.length >= 1) {
      setSponserFirstName({ ...sponserFirstName, verify: true });
    } else {
      setSponserFirstName({ ...sponserFirstName, verify: false });
    }

    if (sponserLastName.value.length >= 1) {
      setSponserLastName({ ...sponserLastName, verify: true });
    } else {
      setSponserLastName({ ...sponserLastName, verify: false });
    }

    if (emailpersonal.value.length > 5) {
      setEmailPersonal({ ...emailpersonal, verify: true });
    } else {
      setEmailPersonal({ ...emailpersonal, verify: false });
    }

    if (whatsappPersonal.value.length >= 11) {
      setWhatsAppPersonal({ ...whatsappPersonal, verify: true });
    } else {
      setWhatsAppPersonal({ ...whatsappPersonal, verify: false });
    }

    if (
      sponserFirstName.verify &&
      sponserLastName.verify &&
      emailpersonal.verify &&
      whatsappPersonal.verify
    ) {
      setInternalBox({ ...internalbox, checked: true });
    } else {
      setInternalBox({ ...internalbox, checked: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    emailpersonal.value,
    whatsappPersonal.value,
    emailpersonal.verify,
    whatsappPersonal.verify,
    sponserLastName.value,
    sponserFirstName.value,
    sponserFirstName.verify,
    sponserLastName.verify,
  ]);

  const [citiesFinded, setCitiesFinded] = useState([]);
  const [tagResultListed, setTagResultListed] = useState([]);
  const [tagSelectedOption, setTagSelectedOption] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [tagResult, setTagResult] = useState([]);

  useEffect(() => {
    if (searchTag === "") {
      setTagResultListed([]);
      return;
    }
    const resultTag = tagResult.filter((term) => {
      return term?.Nome.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          searchTag
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .trim()
        );
    });
    setTagResultListed(resultTag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTag]);

  useEffect(() => {
    if (searchTerm === "") {
      setCitiesFinded([]);
      return;
    }

    const resultSearch = cities.filter((city) => {
      return city?.Nom_Cidade.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          searchTerm
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .trim()
        );
    });
    setCitiesFinded(resultSearch);

    console.log("cities:", resultSearch);
  }, [searchTerm]);

  const [fantasyName, setFantasyName] = useState({
    value: "",
    verify: false,
    error: false,
  });

  const [mainActivity, setMainActivity] = useState({
    value: "",
    verify: false,
    error: false,
  });

  const [address, setAddress] = useState({
    value: "",
    verify: false,
    error: false,
  });

  const [addressNumber, setAddressNumber] = useState({
    value: "",
    verify: false,
    error: false,
  });

  const [neighborhood, setNeighborhood] = useState({
    value: "",
    verify: false,
    error: false,
  });

  const [cep, setCep] = useState({
    value: "",
    verify: false,
    error: false,
  });

  const [complement, setComplement] = useState({
    value: "",
    verify: false,
    error: false,
  });

  const [landline, setLandline] = useState({
    masked: "",
    value: "",
    verify: false,
    error: false,
  });

  const [tagError, setTagError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const [selectProdutc, setSelectProdutc] = useState(null);
  const [selectProdutcError, setSelectProdutcError] = useState(false);

  const [operatorError, setOperatorError] = useState(false);

  const landlineValue = landline.value;
  const cellphoneValue = cellphones[0].value ?? "";

  const landlineIsOptional =
    cellphoneValue.length > 0 && landlineValue.length === 0;
  const cellphoneIsOptional =
    landlineValue.length > 0 && cellphoneValue.length === 0;

  function verifyBusinessInformations() {
    if (fantasyName.value.length <= 0)
      setFantasyName({ ...fantasyName, error: true });

    if (mainActivity.value.length <= 0)
      setMainActivity({ ...mainActivity, error: true });

    if (addressNumber.value.length <= 0)
      setAddressNumber({ ...addressNumber, error: true });

    if (address.value.length <= 0) setAddress({ ...address, error: true });

    if (neighborhood.value.length <= 0)
      setNeighborhood({ ...neighborhood, error: true });

    if (cep.value.length <= 7) setCep({ ...cep, error: true });

    if (!tagSelectedOption.CategoriaId) setTagError(true);

    if (cityId.Cod_Cidade === 0) setCityError(true);

    const landlineIsValid = Validators.landline(landlineValue);
    const cellphoneIsValid = Validators.cellphone(cellphoneValue);

    if (!cellphoneIsValid && !cellphoneIsOptional) {
      setPhoneError(true);
    }

    if (cellphoneIsValid && cellphones[0].mobile === "") {
      setOperatorError(true);
    }

    if (selectProdutc === (0 || null) && franchisedRegistration) {
      setSelectProdutcError(true);
    }

    if (!landlineIsValid && !landlineIsOptional) {
      setLandline((draft) => ({ ...draft, error: true }));
    }

    if (publicBusiness.checked) {
      setRegisterScreen({ ...registerScreen, visible: true });
      setPublicBusiness({ ...publicBusiness, visible: false });
      setPageView("dados-da-empresa");
      titlepage.current = "Cadastre-se no Cliquei Achei | Cadastro do Login";
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const [verifyCity, setverifyCity] = useState(false);
  const [verifyTag, setverifyTag] = useState(false);

  useEffect(() => {
    if (fantasyName.value.length > 0) {
      setFantasyName({ ...fantasyName, verify: true });
    } else {
      setFantasyName({ ...fantasyName, verify: false });
    }

    if (mainActivity.value.length > 0) {
      setMainActivity({ ...mainActivity, verify: true });
    } else {
      setMainActivity({ ...mainActivity, verify: false });
    }

    if (addressNumber.value.length > 0) {
      setAddressNumber({ ...addressNumber, verify: true });
    } else {
      setAddressNumber({ ...addressNumber, verify: false });
    }
    if (address.value.length > 0) {
      setAddress({ ...address, verify: true });
    } else {
      setAddress({ ...address, verify: false });
    }
    if (neighborhood.value.length > 0) {
      setNeighborhood({ ...neighborhood, verify: true });
    } else {
      setNeighborhood({ ...neighborhood, verify: false });
    }

    if (cep.value.length >= 8) {
      setCep({ ...cep, verify: true });
    } else {
      setCep({ ...cep, verify: false });
    }

    if (tagSelectedOption.CategoriaId !== 0) {
      setverifyTag(true);
    } else {
      setverifyTag(false);
    }

    if (cityId.Cod_Cidade !== 0) {
      setverifyCity(true);
    } else {
      setverifyCity(false);
    }

    const landlineIsValid = Validators.landline(landlineValue);
    const cellphoneIsValid = Validators.cellphone(cellphoneValue);

    if (cellphoneIsValid && !cellphoneIsOptional) {
      setPhoneError(false);
      setOperatorError(false);
    }

    if (landlineIsValid && !landlineIsOptional) {
      setLandline((draft) => ({ ...draft, error: false }));
    }

    if (selectProdutc !== null) {
      setSelectProdutcError(false);
    }else{
      setSelectProdutcError(true);
    }

    if (selectProdutc > 0) {
      setSelectProdutcError(false);
    }else{
      setSelectProdutcError(true);
    }

    if (
      neighborhood.verify &&
      address.verify &&
      addressNumber.verify &&
      mainActivity.verify &&
      fantasyName.verify &&
      (franchisedRegistration ? (selectProdutcError === false) : true) &&
      verifyCity &&
      verifyTag &&
      (franchisedRegistration ? (selectProdutc !== null) : true ) &&
      cep.verify &&
      ((cellphoneIsValid &&
        !cellphoneIsOptional &&
        cellphones[0].mobile !== "") ||
        (landlineIsValid && !landlineIsOptional))
    ) {
      setPublicBusiness({ ...publicBusiness, checked: true });
    } else {
      setPublicBusiness({ ...publicBusiness, checked: false });
    }
  }, [
    selectProdutcError,
    neighborhood.value,
    address.value,
    selectProdutc,
    cep.value,
    fantasyName.value,
    addressNumber.value,
    tagSelectedOption.CategoriaId,
    cityId.Cod_Cidade,
    fantasyName.verify,
    mainActivity.verify,
    addressNumber.verify,
    address.verify,
    neighborhood.verify,
    cep.verify,
    cellphones,
    verifyCity,
    verifyTag,
    publicBusiness.checked,
    landline.value,
  ]);

  const [emailRegister, setEmailRegister] = useState({
    value: "",
    verify: false,
    error: false,
  });
  const [emailRegisterConfirm, setEmailRegisterConfirm] = useState({
    value: "",
    verify: false,
    error: false,
  });

  const [password, setPassword] = useState({
    value: "",
    verify: false,
    error: false,
  });

  const [passwordConfirm, setPasswordConfirm] = useState({
    value: "",
    verify: false,
    error: false,
  });

  useEffect(() => {
    if (password.value.length > 5) {
      setPassword({ ...password, verify: true });
    } else {
      setPassword({ ...password, verify: false });
    }

    if (passwordConfirm.value.length > 5) {
      setPasswordConfirm({ ...passwordConfirm, verify: true });
    } else {
      setPasswordConfirm({ ...passwordConfirm, verify: false });
    }

    if (emailRegister.value.length > 4) {
      setEmailRegister({ ...emailRegister, verify: true });
    } else {
      setEmailRegister({ ...emailRegister, verify: false });
    }

    if (emailRegisterConfirm.value.length > 4) {
      setEmailRegisterConfirm({ ...emailRegisterConfirm, verify: true });
    } else {
      setEmailRegisterConfirm({ ...emailRegisterConfirm, verify: false });
    }

    if (selectProdutc !== null) {
      setSelectProdutcError(false);
    }else{
      setSelectProdutcError(true);
    }

    if (selectProdutc > 0) {
      setSelectProdutcError(false);
    }else{
      setSelectProdutcError(true);
    }

    if (
      emailRegisterConfirm.verify &&
      emailRegister.verify &&
      password.verify &&
      passwordConfirm.verify
    ) {
      setRegisterScreen({ ...registerScreen, checked: true });
    } else {
      setRegisterScreen({ ...registerScreen, checked: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    emailRegister.value,
    emailRegisterConfirm.value,
    password.value,
    passwordConfirm.value,
    emailRegister.verify,
    emailRegisterConfirm.verify,
    password.verify,
    passwordConfirm.verify,
    selectProdutc
  ]);

  function verifyRegister() {
    if (password.value.length <= 5) {
      setPassword({ ...password, error: true });
    }

    if (passwordConfirm.value !== password.value) {
      setPasswordConfirm({ ...passwordConfirm, error: true });
    }

    if (
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailRegister.value)
    ) {
    } else {
      setEmailRegister({ ...emailRegister, error: true });
    }

    if (emailRegisterConfirm.value !== emailRegister.value) {
      setEmailRegisterConfirm({ ...emailRegisterConfirm, error: true });
    } else {
      setRegisterScreen({ ...registerScreen, checked: false });
    }

    if (registerScreen.checked) {
      if (
        emailRegister.value === emailRegisterConfirm.value &&
        password.value === passwordConfirm.value
      ) {
        setPageView("cadastro-realizado");
        console.log("cadastro-realizado");
        registerNewClient();
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }

  const [isLoading, setIsLoading] = useState(false);
  const [thankspage, setThankspage] = useState(false);

  const valueProgress = useProgressValue({
    internalBox: internalbox,
    publicBusiness: publicBusiness,
    registerScreen: registerScreen,
    thanksPage: thankspage,
  });

  const [seePassword, setSeePassword] = useState(false);

  function eyePassword() {
    if (seePassword) {
      setSeePassword(false);
    } else {
      setSeePassword(true);
    }
  }

  function registerNewClient() {
    let formsend = {
      Celulares: cellphones.map((item, index) => {
        return {
          OperadoraId: item.mobile,
          Numero: item.value,
          UsaWhatsApp: item.haveWhatsApp,
        };
      }),

      Telefones: [
        {
          NumeroCompleto: landline.value,
        },
      ],
      TelefonesDiversos: [
        {
          NumeroCompleto: "",
        },
      ],
      BitTemTour: false,
      BitTesteGratis: false,
      NomeEmpresario: sponserFirstName.value,
      WhatsAppEmpresario: whatsappPersonal.value,
      EmailEmpresario: emailpersonal.value,
      PrimeiroNome: sponserFirstName.value,
      Sobrenome: sponserLastName.value,
      NomeFantasia: fantasyName.value,
      AtividadePrincipal: mainActivity.value,
      CategoriaId: tagSelectedOption.CategoriaId,
      CidadeId: cityId.Cod_Cidade,
      Endereco: address.value,
      Numero: addressNumber.value,
      Complemento: complement.value,
      Bairro: neighborhood.value,
      CEP: cep.value,
      Email: emailRegister.value,
      Senha: password.value,
      ObservacoesAtendimento: "",
      HotmartTransactionId: tokenId,
      ModeloPaginaId: franchisedRegistration ? selectProdutc : pageModelId,
    };

    if (formsend !== null) {
      setRegisterScreen({ ...registerScreen, visible: false });
      setIsLoading(true);
      axios
        .post("https://api.cliqueiachei.com.br/v1/clientes/new", formsend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setThankspage(true);
          console.log(JSON.stringify(formsend));
        })
        .catch((error) => {
          alert("Ocorreu um erro inesperado: ", error);
          window.location.reload();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loadPage}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="fixedOnTop">
        <LinearProgress
          variant="determinate"
          value={valueProgress}
          color="secondary"
          className="size"
        />
      </div>

      <div className="formContainer">
        <div className="titleHeader fadeIn">
          <img alt="Cliquei Achei" {...logoConfig} />

          {!thankspage ? (
            <h3 className="title">Criar novo Cliquei Achei ID</h3>
          ) : (
            <h3 className="title">
              {franchisedRegistration && !invalidCode
                ? "Empresa cadastrada com sucesso!"
                : !invalidCode
                  ? "Seu Cliquei Achei ID foi criado com sucesso!"
                  : "Parece que você já fez este procedimento..."}
            </h3>
          )}

          {invalidCode && (
            <h4 className="subtitle">
              Siga os passos explicados no vídeo abaixo para poder usufruir de
              todos os benefícios do Cliquei Achei.
            </h4>
          )}

          {internalbox.visible && (messageHead || franchisedRegistration) && (
            <h4 className="subtitle">
              {!franchisedRegistration
                ? messageHead
                : "Nesta página você poderá realizar o cadastro de uma nova empresa."}
            </h4>
          )}
        </div>

        {isLoading && <Loading />}

        {thankspage && (
          <div className="formbox fadeIn">
            {!franchisedRegistration ? (
              <>
                <div className="videoEmbedCustom">
                  <div className="warningSound" />
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      title="just-for-remove-warning"
                      {...videoConfig}
                      autoPlay
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                    />
                  </div>
                </div>

                <div className="groupMyButtons">
                  <div className="itemSupport">
                    <h3>Canal de Suporte</h3>
                    <a
                      href={`https://api.whatsapp.com/send?phone=5535999555999&text=${encodeURI(
                        (hotmartClientName
                          ? "*Nome:* " + hotmartClientName
                          : "") +
                        (fantasyName.value
                          ? ", Empresa:* " + fantasyName.value
                          : "") +
                        (productName ? ", *Produto:* " + productName : "") +
                        (paymentStatusLabel
                          ? ", *Status:* " + paymentStatusLabel
                          : "") +
                        (tokenId ? ", *Transação:* " + tokenId : "")
                      )}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img src={whatsappIcon} alt="Whatsapp Icon" />
                      <span>Chame no WhatsApp</span>
                    </a>
                  </div>

                  <div className="downloadBox">
                    <h3>Baixe os aplicativos</h3>

                    <div className="itemDownload">
                      <div className="logoItem">
                        <img src={divulgadorSmall} alt="Divulgador Icon" />
                      </div>

                      {!isMobile && (
                        <div className="btnBox">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.appdivulgador"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="android_btn"
                          >
                            <img src={androidIcon} alt="Android Icon" />
                            <span>Android</span>
                          </a>

                          <a
                            href="https://apps.apple.com/br/app/divulgador-de-empresas/id1499140138"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="apple_btn"
                          >
                            <img src={appleIcon} alt="Apple Icon" />
                            <span>iPhone</span>
                          </a>
                        </div>
                      )}

                      {isAndroid ? (
                        <div className="btnBox">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.appdivulgador"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="android_btn"
                          >
                            <img src={androidIcon} alt="Android Icon" />
                            <span>Instalar</span>
                          </a>
                        </div>
                      ) : (
                        isIOS && (
                          <div className="btnBox">
                            <a
                              href="https://apps.apple.com/br/app/divulgador-de-empresas/id1499140138"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="apple_btn"
                            >
                              <img src={appleIcon} alt="Apple Icon" />
                              <span>Instalar</span>
                            </a>
                          </div>
                        )
                      )}
                    </div>

                    <div className="itemDownload">
                      <div className="logoItem">
                        <img src={cliqueiacheiSmall} alt="Cliquei Achei Icon" />
                      </div>

                      {!isMobile && (
                        <div className="btnBox">
                          <a
                            href="https://play.google.com/store/apps/details?id=br.com.cliqueiachei"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="android_btn"
                          >
                            <img src={androidIcon} alt="Android Icon" />
                            <span>Android</span>
                          </a>

                          <a
                            href="https://apps.apple.com/br/app/cliquei-achei-superapp/id1081949439"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="apple_btn"
                          >
                            <img src={appleIcon} alt="Apple Icon" />
                            <span>iPhone</span>
                          </a>
                        </div>
                      )}

                      {isAndroid ? (
                        <div className="btnBox">
                          <a
                            href="https://play.google.com/store/apps/details?id=br.com.cliqueiachei"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="android_btn"
                          >
                            <img src={androidIcon} alt="Android Icon" />
                            <span>Instalar</span>
                          </a>
                        </div>
                      ) : (
                        isIOS && (
                          <div className="btnBox">
                            <a
                              href="https://apps.apple.com/br/app/cliquei-achei-superapp/id1081949439"
                              rel="noopener noreferrer"
                              target="_blank"
                              className="apple_btn"
                            >
                              <img src={appleIcon} alt="Apple Icon" />
                              <span>Instalar</span>
                            </a>
                          </div>
                        )
                      )}
                    </div>

                    {Boolean(isPedidos || isBarriga) && (
                      <div className="itemDownload">
                        <div className="logoItem">
                          <img src={printerSmall} alt="Printer Icon" />
                        </div>

                        <div className="btnBox">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.cliqueiacheiimpressora"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="android_btn"
                          >
                            <img src={androidIcon} alt="Android Icon" />
                            <span>
                              {!isAndroid ? "Somente para Android" : "Instalar"}
                            </span>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="franchisedRegistration">
                <h6>Cadastro realizado com sucesso!</h6>
              </div>
            )}
          </div>
        )}

        {internalbox.visible && (
          <OwnerInformationStep
            emailpersonal={emailpersonal}
            setEmailPersonal={setEmailPersonal}
            internalbox={internalbox}
            sponserFirstName={sponserFirstName}
            setSponserFirstName={setSponserFirstName}
            sponserLastName={sponserLastName}
            setSponserLastName={setSponserLastName}
            whatsappPersonal={whatsappPersonal}
            setWhatsAppPersonal={setWhatsAppPersonal}
            verifyPrimaryBox={verifyPrimaryBox}
          />
        )}
        {registerScreen.visible && (
          <div className="fadeIn">
            <div className="formbox">
              <div>
                <div className="titleInformation">
                  <h5 className="itemtitle">Informações de Login</h5>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-6   mb-3">
                    <label>E-mail</label>
                    <div className="input-group mb-1">
                      <input
                        value={emailRegister.value}
                        onChange={(e) => {
                          setEmailRegister({
                            ...emailRegister,
                            value: e.target.value.toLowerCase().trim(),
                            error: false,
                          });
                        }}
                        placeholder="Ex.: joao@gmail.com"
                        type="email"
                        className={`form-control-style-custom ${emailRegister.error ? "error" : ""
                          }`}
                      />
                      {emailRegister.error && (
                        <small className="form-text text-danger">
                          Insira seu e-mail para acessar o Divulgador.
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6   mb-3">
                    <label>Confirme seu e-mail</label>
                    <div className="input-group mb-1">
                      <input
                        value={emailRegisterConfirm.value}
                        onChange={(e) => {
                          setEmailRegisterConfirm({
                            ...emailRegisterConfirm,
                            value: e.target.value.toLowerCase().trim(),
                            error: false,
                          });
                        }}
                        placeholder="Ex.: joao@gmail.com"
                        type="email"
                        className={`form-control-style-custom ${emailRegisterConfirm.error ? "error" : ""
                          }`}
                      />
                      {emailRegisterConfirm.error && (
                        <small className="form-text text-danger">
                          Confirme seu e-mail.
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-5   mb-3">
                    <label>Sua senha</label>
                    <div className="input-group mb-1">
                      <input
                        value={password.value}
                        onChange={(e) => {
                          setPassword({
                            ...password,
                            value: e.target.value,
                            error: false,
                          });
                        }}
                        placeholder="Insira uma senha segura"
                        type={seePassword ? "text" : "password"}
                        className={`form-control-style-custom ${password.error ? "error" : ""
                          }`}
                      />
                      {password.error && (
                        <small className="form-text text-danger">
                          Sua senha deve conter mais de 6 caracteres.
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5   mb-3">
                    <label>Confirme sua senha</label>
                    <div className="input-group mb-1">
                      <input
                        value={passwordConfirm.value}
                        onChange={(e) => {
                          setPasswordConfirm({
                            ...passwordConfirm,
                            value: e.target.value,
                            error: false,
                          });
                        }}
                        placeholder="Confirme sua senha"
                        type={seePassword ? "text" : "password"}
                        className={`form-control-style-custom ${passwordConfirm.error ? "error" : ""
                          }`}
                      />

                      {passwordConfirm.error && (
                        <small className="form-text text-danger">
                          Confime sua senha. Sua senha deve conter mais de 6
                          caracteres.
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-2  mb-3">
                    <label></label>
                    <label></label>

                    <FormControlLabel
                      label={!seePassword ? "Mostrar Senha" : "Ocultar Senha"}
                      control={
                        <Switch
                          checked={seePassword}
                          onChange={() => eyePassword()}
                          name="checkedB"
                          color="primary"
                        />
                      }
                    />
                  </div>
                </div>

                <div className="row justify-content-end">
                  <div className="col-sm-6 col-md-6  col-lg-3"></div>
                  <div className="col-sm-6 col-md-6  col-lg-3">
                    <div className="continueBox">
                      <div
                        className={
                          registerScreen.checked
                            ? "btn-continue"
                            : "btn-disable"
                        }
                        onClick={() => verifyRegister()}
                      >
                        Cadastrar
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {publicBusiness.visible && (
          <BusinessInformationStep
            franchisedRegistration={franchisedRegistration}
            setSelectProdutc={setSelectProdutc}
            selectProdutc={selectProdutc}
            selectProdutcError={selectProdutcError}
            setSelectProdutcError={setSelectProdutcError}
            address={address}
            addressNumber={addressNumber}
            alertError={alertError}
            cellphones={cellphones}
            cep={cep}
            cityError={cityError}
            cityId={cityId}
            complement={complement}
            descriptionElementRef={descriptionElementRef}
            fantasyName={fantasyName}
            landline={landline}
            mainActivity={mainActivity}
            mobileOperators={mobileOperators}
            neighborhood={neighborhood}
            open={open}
            operatorError={operatorError}
            phoneError={phoneError}
            publicBusiness={publicBusiness}
            scroll={scroll}
            tagError={tagError}
            tagSelect={tagSelect}
            tagSelectedOption={tagSelectedOption}
            citiesFinded={citiesFinded}
            searchTag={searchTag}
            searchTerm={searchTerm}
            setOpen={setOpen}
            setSearchTag={setSearchTag}
            setSearchTerm={setSearchTerm}
            setTagSelect={setTagSelect}
            setTagSelectedOption={setTagSelectedOption}
            tagResultListed={tagResultListed}
            setCityId={setCityId}
            verifyThirdBox={verifyBusinessInformations}
            setAddress={setAddress}
            setAddressNumber={setAddressNumber}
            setAlertError={setAlertError}
            setCellphones={setCellphones}
            setCep={setCep}
            setComplement={setComplement}
            setFantasyName={setFantasyName}
            setLandline={setLandline}
            setMainActivity={setMainActivity}
            setNeighborhood={setNeighborhood}
            setOperatorError={setOperatorError}
            setPhoneError={setPhoneError}
            handleNewPhone={handleNewPhone}
            handleClickOpen={handleClickOpen}
            handleClickTag={handleClickTag}
            handleClose={handleClose}
            handleCloseTag={handleCloseTag}
            cellphoneIsOptional={cellphoneIsOptional}
            landlineIsOptional={landlineIsOptional}
          />
        )}
      </div>
      <div className="signatureLabs" />
    </div>
  );
}
