import { LinearProgress } from "@material-ui/core";
import React from "react";

export default function Loading() {
  return (
    <div className="fadeIn">
      <div className="formbox">
        <div>
          <div className="titleInformation mt-3">
            <h5 className="itemtitle text-center">Finalizando o cadastro...</h5>
            <LinearProgress variant="indeterminate" color="primary" />
          </div>
        </div>
      </div>
    </div>
  );
}
